<template>
  <div>
    <GridColumnMenuCheckboxFilter
      :column="column"
      :filter="filter"
      :filterable="filterable"
      :search-box="false"
      :data-items="[
        { updateReason: 'anacredit' },
        { updateReason: 'extendednace' }
      ]"
      :expanded="true"
      @expandchange="expandChange"
      @closemenu="closeMenu"
      @filterchange="filterChange"
    />
  </div>
</template>

<script>
import { GridColumnMenuCheckboxFilter } from '@progress/kendo-vue-grid'
import { mapActions, mapState } from 'vuex/dist/vuex.common.js'

export default {
  components: {
    GridColumnMenuCheckboxFilter
  },
  props: {
    column: Object,
    filter: Object,
    filterable: Boolean,
    uniqueData: {
      type: Boolean,
      default: true
    },
    gridData: Array
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('pmDashboard', ['filteredColumnList']),
    oneVisibleColumn() {
      return this.currentColumns.filter((c) => !c.hidden).length === 1
    }
  },
  created() {
    this.$data.currentColumns = this.$props.columns
  },
  emits: [
    'columnssubmit',
    'closemenu',
    'expandchange',
    'filterchange',
    'sortchange'
  ],
  methods: {
    ...mapActions('pmDashboard', ['addFilteredColumnList']),
    handleFocus(e) {
      this.$emit('contentfocus', e)
    },
    expandChange() {
      this.$emit('expandchange')
    },
    closeMenu() {
      this.$emit('closemenu')
    },
    filterChange(newDescriptor, e) {
      let processedDescriptor = null
      let nestedFilters = null // Initialize nestedFilters to null

      if (newDescriptor && newDescriptor.filters.length > 0) {
        // Extract filters specific to `updateReason`
        nestedFilters = newDescriptor.filters.find(
          (f) =>
            f.logic === 'or' &&
            Array.isArray(f.filters) &&
            f.filters.some((filter) => filter.field === 'updateReason')
        )

        if (nestedFilters) {
          // Extract user selections for `updateReason`
          const selectedValues = nestedFilters.filters
            .filter((f) => f.field === 'updateReason')
            .map((f) => String(f.value).toLowerCase())

          // Process filters for `updateReason`
          if (
            selectedValues.includes('anacredit') &&
            selectedValues.includes('extendednace')
          ) {
            nestedFilters.filters = [
              {
                field: 'updateReason',
                operator: 'contains',
                value: 'anacredit'
              },
              {
                field: 'updateReason',
                operator: 'contains',
                value: 'extendednace'
              }
            ]
          } else if (selectedValues.includes('anacredit')) {
            nestedFilters.filters = [
              {
                field: 'updateReason',
                operator: 'contains',
                value: 'anacredit'
              }
            ]
          } else if (selectedValues.includes('extendednace')) {
            nestedFilters.filters = [
              {
                field: 'updateReason',
                operator: 'contains',
                value: 'extendednace'
              }
            ]
          } else {
            nestedFilters.filters = [] // Clear filters if nothing is selected
          }

          // Wrap the updated filter in an "AND" group
          processedDescriptor = {
            logic: 'and',
            filters: [nestedFilters]
          }
        }
      }

      // Merge with the parent filter
      const parentFilter = this.$props.filter || { logic: 'and', filters: [] }

      const updatedFilters = parentFilter.filters.filter(
        (f) => !f.filters.some((filter) => filter.field === 'updateReason')
      )

      if (processedDescriptor) {
        updatedFilters.push(...processedDescriptor.filters)
      }

      const finalDescriptor = {
        logic: 'and',
        filters: updatedFilters
      }

      // Determine if the filter for this column is applied
      const isFilterApplied = !!(
        nestedFilters && nestedFilters.filters.length > 0
      )

      // Highlight the column using the isFilterApplied flag
      this.addFilteredColumnList({
        columnName: 'updateReason',
        isFilter: isFilterApplied
      })

      // Emit the final filter descriptor to the parent
      this.$emit('filterchange', finalDescriptor, e)
    },
    sortChange(newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e)
    },
    sortChange(newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e)
    }
  }
}
</script>
