<template>
  <div>
    <GridColumnMenuCheckboxFilter
      :column="column"
      :filter="filter"
      :filterable="filterable"
      :search-box="false"
      :data-items="[{ isUpdatedValue: 'Yes' }, { isUpdatedValue: 'No' }]"
      :expanded="true"
      @expandchange="expandChange"
      @closemenu="closeMenu"
      @filterchange="filterChange"
    />
  </div>
</template>
<script>
import { GridColumnMenuCheckboxFilter } from '@progress/kendo-vue-grid'
import { mapActions, mapState } from 'vuex/dist/vuex.common.js'

export default {
  components: {
    GridColumnMenuCheckboxFilter: GridColumnMenuCheckboxFilter
  },
  props: {
    column: Object,
    filter: Object,
    filterable: Boolean,
    uniqueData: {
      type: Boolean,
      default: true
    },
    gridData: Array
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('pmDashboard', ['filteredColumnList']),
    oneVisibleColumn() {
      return this.currentColumns.filter((c) => !c.hidden).length === 1
    }
  },
  created() {
    this.$data.currentColumns = this.$props.columns
  },
  emits: [
    'columnssubmit',
    'closemenu',
    'expandchange',
    'filterchange',
    'sortchange'
  ],
  methods: {
    ...mapActions('pmDashboard', ['addFilteredColumnList']),
    handleFocus(e) {
      this.$emit('contentfocus', e)
    },
    onToggleColumn(id) {
      this.currentColumns = this.currentColumns.map((column, idx) => {
        return idx === id ? { ...column, hidden: !column.hidden } : column
      })
    },
    onReset(event) {
      event.preventDefault()
      const allColumns = this.$props.columns.map((col) => {
        return {
          ...col,
          hidden: false
        }
      })

      this.currentColumns = allColumns
      this.onSubmit()
    },
    onSubmit(event) {
      if (event) {
        event.preventDefault()
      }
      this.$emit('columnssubmit', this.currentColumns)
      this.$emit('closemenu')
    },
    onMenuItemClick() {
      const value = !this.columnsExpanded
      this.columnsExpanded = value
      this.filterExpanded = value ? false : this.filterExpanded
    },
    onFilterExpandChange(value) {
      this.filterExpanded = value
      this.columnsExpanded = value ? false : this.columnsExpanded
    },
    expandChange() {
      this.$emit('expandchange')
    },
    closeMenu() {
      this.$emit('closemenu')
    },
    filterChange(newDescriptor, e) {
      let isFilter = !!newDescriptor
      if (
        newDescriptor &&
        newDescriptor.filters.length !== this.filteredColumnList.length
      ) {
        const index = this.filteredColumnList.indexOf('isUpdatedValue')
        isFilter = !(index > -1) // if found in the list set to false
      } else if (
        newDescriptor &&
        newDescriptor.filters.length === this.filteredColumnList.length &&
        e.type === 'reset'
      ) {
        const index = this.filteredColumnList.indexOf('isUpdatedValue')
        isFilter = index > -1
      }
      this.addFilteredColumnList({
        columnName: 'isUpdatedValue',
        isFilter: isFilter
      })
      this.$emit('filterchange', newDescriptor, e)
    },
    sortChange(newDescriptor, e) {
      this.$emit('sortchange', newDescriptor, e)
    }
  }
}
</script>
