<template>
  <div>
    <dropdown-wrap-vue @onClickOutside="closePopup">
      <b-button
        class="action-button assign-button"
        size="is-small"
        ref="button"
        @click="togglePopup"
      >
        <b-icon icon="clipboard-check" size="is-small" class="toolbar-icons">
        </b-icon>
        &nbsp; {{ dialogName }}
      </b-button>
      <div v-if="showPopup" class="popup">
        <div class="popup-content">
          <k-input
            v-if="isSearchRequired"
            :placeholder="'Search'"
            :inputPrefix="'prefix'"
            v-model="searchQuery"
          >
            <template v-if="dialogName === 'Assign'" v-slot:prefix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
          <div v-if="filteredItems && filteredItems.length > 0">
            <div
              class="checkbox-container"
              v-for="(data, index) in filteredItems"
              :key="index"
              @click="select(data)"
            >
              <p v-if="dialogName === 'Assign'">{{ data.name }}</p>
              <p v-else>{{ data }}</p>
            </div>
          </div>
          <div class="no-data-container" v-else>No data found</div>
        </div>
      </div>
    </dropdown-wrap-vue>
  </div>
</template>

<script>
import { Input } from '@progress/kendo-vue-inputs'
import DropdownWrapVue from '@/components/Common/DropdownWrap.vue'

export default {
  components: {
    'k-input': Input,
    DropdownWrapVue
  },
  props: {
    items: {
      type: Array
    },
    dialogName: {
      type: String,
      default: null
    },
    isSearchRequired: {
      type: Boolean,
      default: false
    },
    // Accepts `value` prop for v-model binding
    value: {
      type: [String, Number, null], // Assuming userId is a string or number
      default: null
    }
  },
  data() {
    return {
      showPopup: false,
      searchQuery: ''
    }
  },
  computed: {
    filteredItems() {
      // Filter team members based on the search query
      if (this.isSearchRequired && this.searchQuery) {
        return this.items.filter((team) =>
          `${team.name}`.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      }
      return this.items
    }
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup
    },
    closePopup() {
      this.showPopup = false
      this.searchQuery = ''
    },
    select(data) {
      // Emit input event to update the parent's v-model value
      this.$emit('updateField', data)
      this.closePopup()
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  z-index: 999;
}

.popup-content {
  background-color: #fff;
  width: 150px;
  height: auto;
  max-height: 250px;
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #888;
}

.k-input,
.k-picker {
  width: 95%;
  margin-left: 2%;
}

.no-data-container {
  text-align: left;
  font-family: Quicksand;
  font-size: 13px;
  padding: 2px;
  font-weight: 400;
  letter-spacing: 0.16px;
  color: #000000;
  opacity: 1;
  margin-left: 4px;
}
.checkbox-container {
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  flex-direction: row;
  width: 100%;
  p {
    text-align: left;
    font-family: Quicksand;
    font-size: 13px;
    margin-left: 5px;
    padding: 2px;
    font-weight: 400;
    letter-spacing: 0.16px;
    color: #000000;
    opacity: 1;
    width: 100%;
  }
}

.checkbox-container :hover {
  background: rgb(204, 204, 207);
}

@media (min-width: 1450px) and (max-width: 1700px) {
  .popup-content {
    position: absolute;
    top: -5px;
    left: 0px;
  }
}
@media (min-width: 1700px) {
  .popup-content {
    position: absolute;
    top: -10px;
    left: 0px;
  }
}
</style>
