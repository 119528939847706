export const pmDashboardColumns = [
  {
    title: 'Entity Details',
    children: [
      {
        field: 'selected',
        width: '45px',
        columnMenu: false,
        groupable: false,
        headerSelectionValue: false
      },
      {
        field: 'sgaId',
        title: 'SGA ID',
        width: '175',
        minResizableWidth: '175',
        cell: 'viewEntityTemplate',
        groupable: false
      },
      {
        field: 'requestId',
        title: 'Request ID',
        width: '170',
        minResizableWidth: '150',
        groupable: false
      },
      {
        field: 'legalEntityName',
        title: 'Legal Entity Name',
        width: '200px',
        groupable: false,
        cell: 'legalEntityTemplate'
      },
      {
        field: 'countryDOMName',
        title: 'Country Of Domicile',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        width: 240,
        groupable: false
      },
      {
        field: 'immediateParentName',
        title: 'Immediate Parent Name',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        groupable: true,
        width: 270
      },
      {
        field: 'ultimateParentName',
        title: 'Ultimate Parent Name',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        groupable: true,
        width: 270
      },
      {
        field: 'lei',
        title: 'LEI',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        groupable: false,
        width: 180
      },
      {
        field: 'lastUpdated',
        title: 'Last Updated'
      },
      {
        field: 'isSubscribed',
        title: 'Challenge',
        className: 'subscription-class',
        cell: 'subscriptionDropDownTemplate',
        groupable: false,
        width: 150
      },
      {
        field: 'workType',
        title: 'Work Type',
        groupable: false,
        width: '300'
      },
      {
        field: 'isOnHoldValue',
        title: 'On Hold',
        width: '140px',
        cell: 'onHoldStatusTemplate',
        sortable: true,
        columnMenu: 'onHoldCustomFilterTemplate',
        filterable: true,
        groupable: false
      },
      {
        field: 'onHoldReasonName',
        title: 'On Hold Reason',
        text: 'On Hold Reason',
        width: '190px',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        sortable: false,
        groupable: false
      },
      {
        field: 'isUpdatedValue',
        title: 'Is Updated',
        width: '150px',
        columnMenu: 'isUpdatedCustomFilterTemplate',
        filterable: true,
        sortable: true,
        groupable: false
      },
      {
        field: 'updateReason',
        title: 'Update Reason',
        width: '200px',
        columnMenu: 'anaCreditNaceCustomFilterTemplate',
        cell: 'formattedAnaCreditNaceFlag',
        filterable: true,
        sortable: false,
        groupable: false
      }
    ]
  },
  {
    title: 'Assignments',
    children: [
      {
        field: 'assignedTo',
        title: 'Assigned To',
        width: 200,
        groupable: false
      },
      {
        field: 'status',
        title: 'Status',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        groupable: true,
        width: 200
      },
      {
        field: 'priority',
        title: 'Priority',
        columnMenu: 'customFilterTemplate',
        filterable: true,
        groupable: true,
        width: 200
      },
      {
        field: 'makerName',
        title: 'Maker Name',
        groupable: false,
        filterable: true,
        columnMenu: 'customFilterTemplate',
        width: 200
      },
      {
        field: 'makerSubmittedTimestamp',
        title: 'Maker Submitted Timestamp',
        groupable: false,
        filterable: false,
        sortable: true,
        cell: 'formattedDateCellTemplate',
        width: 280
      },
      {
        field: 'checkerName',
        title: 'Checker Name',
        groupable: false,
        filterable: true,
        columnMenu: 'customFilterTemplate',
        width: 200
      },
      {
        field: 'checkerSubmittedTimestamp',
        title: 'Checker Submitted Timestamp',
        groupable: false,
        filterable: false,
        sortable: true,
        cell: 'formattedDateCellTemplate',
        width: 290
      },
      {
        field: 'lastUpdatedBy',
        title: 'Last Updated By',
        groupable: false,
        filterable: true,
        columnMenu: 'customFilterTemplate',
        width: 200
      },
      {
        field: 'lastUpdatedTimestamp',
        title: 'Last Updated Timestamp',
        groupable: false,
        filterable: false,
        sortable: true,
        cell: 'formattedDateCellTemplate',
        width: 280
      }
    ]
  }
]
